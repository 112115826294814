<template>
  <b-sidebar
    id="add-new-payment-sidebar"
    :visible="isAddNewPaymentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-payment-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Add Payment
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Reference -->
          <validation-provider
            #default="validationContext"
            name="Reference"
            rules="required"
          >
            <b-form-group
              label="Reference"
              label-for="reference"
            >
              <b-form-input
                id="reference"
                v-model="paymentData.reference"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Reference"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Client Name -->
          <validation-provider
            #default="validationContext"
            name="Client Name"
            rules="required"
          >
            <b-form-group
              label="Client Name"
              label-for="client-name"
            >
              <v-select
                id="client-name"
                v-model="paymentData.clientName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clientName"
                :clearable="false"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Invoice -->
          <validation-provider
            #default="validationContext"
            name="Invoice"
            rules="required"
          >
            <b-form-group
              label="Invoice"
              label-for="invoice"
            >
              <v-select
                id="invoice"
                v-model="paymentData.invoice"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="invoice"
                :clearable="false"
                class="per-page-selector ml-0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Amount -->
          <validation-provider
            #default="validationContext"
            name="Amount"
            rules="required"
          >
            <b-form-group
              label="Amount"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="paymentData.amount"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Unused Amount -->
          <validation-provider
            #default="validationContext"
            name="Unused Amount"
            rules="required"
          >
            <b-form-group
              label="Unused Amount"
              label-for="unused-amount"
            >
              <b-form-input
                id="unused-amount"
                v-model="paymentData.unusedAmount"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Payment -->
          <validation-provider
            #default="validationContext"
            name="Payment"
            rules="required"
          >
            <b-form-group
              label="Payment"
              label-for="payment"
            >
              <b-form-input
                id="payment"
                v-model="paymentData.payment"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Mode -->
          <validation-provider
            #default="validationContext"
            name="Mode"
            rules="required"
          >
            <b-form-group
              label="Mode"
              label-for="mode"
            >
              <v-select
                id="mode"
                v-model="paymentData.mode"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="mode"
                :clearable="false"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Date -->
          <validation-provider
            #default="validationContext"
            name="Date"
            rules="required"
          >
            <b-form-group
              label="Date"
              label-for="date"
            >
              <b-form-datepicker
                id="date"
                v-model="paymentData.date"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormDatepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewPaymentSidebarActive",
    event: "update:is-add-new-payment-sidebar-active",
  },
  props: {
    isAddNewPaymentSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      invoice: ["Invoice 2", "Invoice 3"],
      clientName: ["Client 1", "Client 2"],
      mode: ["Cash", "Insaiiment", "Visa"],
    };
  },
  setup(props, { emit }) {
    const blankPaymentData = {
      reference: "",
      clientName: "",
      invoice: "",
      mode: "",
      payment: "",
      date: "",
      amount: "",
      unusedAmount: "",
    };

    const paymentData = ref(JSON.parse(JSON.stringify(blankPaymentData)));
    const resetpaymentData = () => {
      paymentData.value = JSON.parse(JSON.stringify(blankPaymentData));
    };
    const onSubmit = () => {
      store.dispatch("app-payment/addPayment", paymentData.value).then(() => {
        emit("refetch-data");
        emit("update:is-add-new-payment-sidebar-active", false);
      });
    };

    // eslint-disable-next-line operator-linebreak
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpaymentData);

    return {
      paymentData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-payment-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
