<template>
  <div>
    <books-payments-list-add-new
      :is-add-new-payment-sidebar-active.sync="isAddNewPaymentSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      class="mb-0"
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              @click="isAddNewPaymentSidebarActive = true"
            >
              Add Record
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="order-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPaymentListTable"
        class="position-relative"
        :items="fetchPayments"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        bordered
      >
        <!-- Column: Payment date -->
        <template #cell(date)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.date }}
            </span>
          </b-media>
        </template>

        <!-- Column: Payment -->
        <template #cell(payment)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.payment }}
            </span>
          </b-media>
        </template>

        <!-- Column: Reference -->
        <template #cell(reference)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.reference }}
            </span>
          </b-media>
        </template>

        <!-- Column: Invoice -->
        <template #cell(invoice)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.invoice }}
            </span>
          </b-media>
        </template>

        <!-- Column: Mode -->
        <template #cell(mode)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.mode }}
            </span>
          </b-media>
        </template>

        <!-- Column: Client Name -->
        <template #cell(clientName)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.clientName }}
            </span>
          </b-media>
        </template>

        <!-- Column: Amount -->
        <template #cell(amount)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.amount }}
            </span>
          </b-media>
        </template>

        <!-- Column: Unused Amount -->
        <template #cell(unusedAmount)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.unusedAmount }}
            </span>
          </b-media>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPayments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BPagination,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useBooksPaymentsList from "./useBooksPaymentsList";
import booksPaymentsStoreModule from "../booksPaymentsStoreModule";
import BooksPaymentsListAddNew from "./BooksPaymentsListAddNew.vue";

export default {
  components: {
    BooksPaymentsListAddNew,

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BPagination,
    BFormInput,

    vSelect,
  },
  setup() {
    // eslint-disable-next-line camelcase
    const Payment_APP_STORE_MODULE_NAME = "app-payment";

    // Register module
    // eslint-disable-next-line curly
    if (!store.hasModule(Payment_APP_STORE_MODULE_NAME))
      // eslint-disable-next-line nonblock-statement-body-position
      store.registerModule(
        Payment_APP_STORE_MODULE_NAME,
        // eslint-disable-next-line comma-dangle
        booksPaymentsStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Payment_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(Payment_APP_STORE_MODULE_NAME);
      }
    });

    const statusOptions = [
      "Downloaded",
      "Draft",
      "Paid",
      "Partial Payment",
      "Past Due",
    ];

    const isAddNewPaymentSidebarActive = ref(false);

    const {
      fetchPayments,
      tableColumns,
      perPage,
      currentPage,
      totalPayments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentListTable,
      refetchData,
      statusFilter,
    } = useBooksPaymentsList();

    return {
      // Sidebar
      isAddNewPaymentSidebarActive,

      fetchPayments,
      tableColumns,
      perPage,
      currentPage,
      totalPayments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentListTable,
      refetchData,
      statusFilter,
      statusOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.order-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.add {
  padding: 0.715rem 0.736rem;
  transform: translateY(-53px);
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
